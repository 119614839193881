@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "JetBrains Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #515e5a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.centerd {
  top: 50%;
  left: 50%;
}
.navbar {
  padding: 2rem;
  color: #e2e2e2;
  text-align: center;
  font-family: "JetBrains Mono";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}
.nav-link {
  padding-left: 2rem !important;
}
.banner {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.hero {
  text-align: center;
  color: white;
}
.hero h1 {
  padding-bottom: 3rem;
  color: white;
  font-size: 4rem;
  font-family: JetBrains Mono;
  font-weight: 800;
  word-wrap: break-word;
}
.hero p {
  padding-bottom: 1rem;
  color: #f5f5f5;
  font-size: 2rem;
  font-family: JetBrains Mono;
  font-weight: 800;
  word-wrap: break-word;
}
.hero a {
  background: #d9d9d9;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 800;
}
.about_div {
  position: relative;
  top: 50%;
  text-align: center;
  color: white;
}
.about_div a {
  background: #d9d9d9;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 800;
}
.upload {
  text-align: center;
}
.upload a {
  background: #d9d9d9;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 800;
}
.upload h5 {
  padding-bottom: 3rem;
  color: white;
  font-size: 4rem;
  font-family: JetBrains Mono;
  font-weight: 800;
  word-wrap: break-word;
}
.center-abs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.span_upload {
  padding-top: 2rem;
  font-size: 1rem;
  font-family: JetBrains Mono;
  font-weight: 800;
}
.spinner {
  color: white;
}
.result img {
  max-width: 90%;
  max-height: 80%;
}
.result .col-md-6 {
  color: white;
  display: flex;
  justify-content: center;
}
.result table {
  align-self: center;
}

table {
  margin: 5%
}

.tx-wh {
  color:white; 
}